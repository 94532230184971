import React from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import CompanyProfileCell from '@fingo/lib/components/cells/CompanyProfileCell';
import ProgressBarCell from '@fingo/lib/components/cells/ProgressBarCell';
import DateTimeCell from '@fingo/lib/components/cells/DateTimeCell';
import Typography from '@mui/material/Typography';
import EditCreditLineCell from '../components/credit-line-manager/EditCreditLineCell';

export const HEADER_TITLE = 'Maestro de líneas';

export const COLUMNS = [
  {
    field: 'name',
    type: 'string',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
    sortable: true,
  },
];

export const CREDIT_LINE_COLUMNS = [
  {
    field: 'lastUpdate',
    type: 'date',
    headerName: 'Fecha última actualización',
    sortable: false,
    disableColumnMenu: true,
    width: 190,
    renderCell: ({ row }) => <DateTimeCell fullDate={dayjs(row.lastUpdate)} />,
  },
  {
    field: 'currency',
    headerName: 'Divisa',
    sortable: false,
    disableColumnMenu: true,
    width: 65,
    renderCell: ({ row }) => <Typography>{row.currency.isoCode}</Typography>,
  },
  {
    field: 'creditUsed',
    headerName: 'Línea usada',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => (
      <ProgressBarCell
        value={row.creditUsed || 0}
        total={row.currentLimit?.newLimit?.amount || 0}
        isMoney
        currency={row.currency.isoCode}
        startTooltipTitle="Línea usada"
        endTooltipTitle="Línea total"
      />
    ),
  },
  {
    field: 'edit',
    headerName: 'Acciones',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => <EditCreditLineCell creditLine={row} />,
    width: 90,
  },
];
