/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import MoneyFields from '@fingo/lib/graphql/fragments/money/money-fields';

export const CreditLineFields = gql`
  fragment CreditLineFields on CreditLineInfoType {
    id
    limitAmount @client
    lastUpdate @client
    creditUsed @client
    currency {
      id
      isoCode
    }
    lastMovement {
      id
      totalCreditUsed {
        ...MoneyFields
      }
      amount {
        ...MoneyFields
      }
      createdAt
    }
    currentLimit {
      id
      createdAt
      newLimit {
        ...MoneyFields
      }
    }
  }
  ${MoneyFields}
`;
