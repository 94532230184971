import React from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import { formatDateTime } from '@fingo/lib/helpers';
import Typography from '@mui/material/Typography';

const CreditLineColumns = [
  {
    field: 'date',
    type: 'date',
    headerName: 'Fecha',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Typography>{formatDateTime(dayjs(row.createdAt))}</Typography>
    ),
    flex: 0.5,
  },
  {
    field: 'amount',
    type: 'string',
    headerName: 'Monto',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Typography>
        {row?.newLimit?.chileFormat ?? row?.totalCreditUsed?.chileFormat}
      </Typography>
    ),
    flex: 0.5,
  },
];

export default CreditLineColumns;
